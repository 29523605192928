<template>
  <div style="margin: 0 15px">
    <ul
      class="nav nav-tabs not-show-scroll"
      style="overflow-y: scroll; flex-wrap: nowrap"
    >
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_Merchandise' }"
          @click="go('PS_Merchandise')"
        >
          商品管理
        </button>
      </li>
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_MerchOrder' }"
          @click="go('PS_MerchOrder')"
        >
          訂單管理
        </button>
      </li>
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_Stock' }"
          @click="go('PS_Stock')"
        >
          庫存管理
        </button>
      </li>
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_PurchaseOrder' }"
          @click="go('PS_PurchaseOrder')"
        >
          叫貨管理
        </button>
      </li>
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_Participant' }"
          @click="go('PS_Participant')"
        >
          顧客管理
        </button>
      </li>
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_Bill' }"
          @click="go('PS_Bill')"
        >
          對帳出貨
        </button>
      </li>
      <li class="nav-item" style="min-width: 120px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_Shipper' }"
          @click="go('PS_Shipper')"
        >
          撿貨出貨單
        </button>
      </li>
      <li class="nav-item" style="min-width: 100px">
        <button
          class="nav-link fw-bolder"
          :class="{ active: currentPage === 'PS_CommonStyles' }"
          @click="go('PS_CommonStyles')"
        >
          常用款式
        </button>
      </li>
      <li class="nav-item" style="min-width: 50px">
        <button
          class="nav-link fw-bolder m-0"
          :class="{ active: currentPage === 'PS_Line' }"
          @click="go('PS_Line')"
        >
          Line
        </button>
      </li>
      <li class="nav-item" style="min-width: 50px">
        <button
          class="nav-link fw-bolder m-0"
          :class="{ active: currentPage === 'PS_Fb' }"
          @click="go('PS_Fb')"
        >
          Fb
        </button>
      </li>
    </ul>
  </div>
  <div class="tw-container p-3">
    <router-view></router-view>
  </div>
</template>

<script>
import { Permission } from '@/methods/mixins/permission';

export default {
  data() {
    return {
      storeId: 0,
      serverToken: '',
      currentPage: 'PS_Merchandise'
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
  },
  watch: {
    $route(to) {
      this.currentPage = to.name
    },
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
      this.currentPage = this.$route.name
    },
    go(where) {
      this.$router.push({name: where})
    },
  },
}
</script>
